<template>
  <div class="font-medium">
    <label :for="id" :class="`${color} mb-1 block text-[0.813rem]`">
      {{ label }}
    </label>
    <select
      :id="id"
      :class="`block w-full rounded-md border border-gray-300 bg-white p-2.5 text-[0.813rem] font-medium ${color} ${focusColor} focus:outline-none`"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option v-for="option in options" :value="option.value">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    label: {
      type: String,
      default: "",
      required: true
    },
    color: {
      type: String,
      default: "primary",
      required: true
    },
    options: {
      type: Array,
      default: [{ value: "value1", text: "Value 1" }],
      required: true
    }
  },
  data() {
    return {
      id: uuidv4()
    };
  },
  computed: {
    color() {
      return "text-" + this.color;
    },
    focusColor() {
      return "focus:border-" + this.color;
    }
  }
};
</script>
